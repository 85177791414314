<template>
  <div
    v-if="salesHits.list?.length"
    :class="[classes.root, 'container']"
  >
    <h2 :class="sectionTitleStyles">
      Снизили все цены на 25% – не пропустите!
    </h2>
    <div
      ref="productList"
      :class="[classes.list]"
      :style="{ 'grid-template-columns': `repeat(${salesHits.list.length}, ${xlAndLarger ? '233px': '148px'})` }"
      @scroll="scroll"
    >
      <div
        v-for="(item, index) in salesHits.list"
        :key="index"
        :class="classes.productCard"
        @click="redirect(item.url)"
      >
        <div
          :class="classes.device"
        >
          <ClientOnly>
            <div
              v-if="item.image_path"
              v-lazy:background-image="`https://static.pedant.market${item.image_path}`"
              :class="[classes.img]"
            ></div>
          </ClientOnly>
          <div :class="classes.deviceContent">
            <div :class="classes.deviceName">
              {{ item.name }}
            </div>
            <div
              v-if="item.count >= 5"
              :class="[classes.deviceVariants, 'text-accent']"
            >
              {{ item.count }}
              {{ declension(item.count, ["вариант", "варианта", "вариантов"]) }}
            </div>
            <div
              v-else
              :class="[classes.deviceVariants, 'text-error']"
            >
              Остал{{ item.count === 1 ? 'ся' : 'ось' }} всего {{ item.count }}
            </div>
          </div>
          <div :class="classes.content">
            <div :class="classes.payment">
              от {{ numberSpace(monthlyPayment(item.basic_price)) }} ₽/мес
            </div>
          </div>
          <BaseStopper
            v-if="item.count > 100"
            :class="[classes.stopper, 'textSize']"
            color="red"
            icon-name="stoppers:fire"
            icon-class-name="me-2"
            :icon-width="12"
            :icon-height="13"
            :text="'хит'"
            :is-lower-text="true"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!isScrollEnd && xlAndLarger"
      :class="[classes.btn, classes.next]"
      @click="scrollLeft"
    >
      <Icon
        name="common:chevron"
        size="12"
        alt=""
      />
    </div>
    <div
      v-if="!isScrollStart && xlAndLarger"
      :class="[classes.btn, classes.prev]"
      @click="scrollRight"
    >
      <Icon
        :class="classes.prevImg"
        name="common:chevron"
        size="12"
        alt=""
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { cx } from 'cva'
import { ROUTE_NAMES } from '@/constants'
import { declension, numberSpace, sectionTitleStyles } from '@/utils'

const classes = {
  root: cx(['relative', 'xl:mb-[80px]']),
  list: cx([
    'overflow-x-auto',
    'grid',
    'gap-[8px]',
    'xl:gap-[20px]',
    'scrollbar-none',
    '-ms-overflow-style-none',
    'scroll-smooth',
    'scrollbar-fix',
  ]),
  productCard: cx([
    'bg-bg-main-primary',
    'cursor-pointer',
    'p-[12px]',
    'rounded-[12px]',
    'grid',
    'grid-rows-auto',
    'gap-[8px]',
    'relative',
    'hover:bg-bg-main-thirdly',
    'transition',
    'duration-300',
    'xl:p-[24px]',
    'xl:gap-20',
  ]),
  device: cx(['grid', 'grid-rows-auto', 'gap-[16px]', 'xl:gap-[18px]']),
  img: cx([
    'h-[100px]',
    'w-[100px]',
    'bg-contain',
    'bg-no-repeat',
    'bg-center',
    'm-auto',
    'xl:h-[146px]',
    'xl:w-[148px]',
  ]),
  deviceContent: cx([
    'grid',
    'grid-rows-[min-content min-content]',
    'gap-[2px]',
    'justify-center',
  ]),
  deviceName: cx([
    'font-bold',
    'text-primary',
    'leading-[16px]',
    'text-center',
    'xl:leading-[19px]',
  ]),
  deviceVariants: cx([
    'font-bold',
    'leading-[12px]',
    'text-center',
    'text-[10px]',
    'xl:text-[14px]',
    'xl:leading-[16px]',
  ]),
  content: cx(['grid', 'grid-rows-[min-content min-content]', 'gap-[2px]']),
  payment: cx([
    'text-center',
    'text-[16px]',
    'leading-[19px]',
    'font-bold',
    'text-primary',
  ]),
  stopper: cx(['absolute', 'top-[15px]', 'right-[15px]']),
  btn: cx([
    'absolute',
    'top-[134px]',
    'w-[32px]',
    'h-[32px]',
    'bg-bg-main-secondary',
    'flex',
    'items-center',
    'justify-center',
    'rounded-full',
    'z-[10]',
    'cursor-pointer',
    'shadow-md',
    'text-disable',
  ]),
  next: 'right-[2px]',
  prev: 'left-[2px]',
  prevImg: 'rotate-180',
}

const salesHits = useSalesHitsStore()

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const monthlyPayment = (price: number) => price * 0.07

const redirect = async (url: string) => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.CLICK_PRODUCT_CARD_HITS_SECTION)

  const newUrl = url.startsWith('/') ? url.slice(1) : url // remove first '/'
  const [modelGroupSlug, modelSlug] = newUrl.split('/')

  await navigateTo({
    name: ROUTE_NAMES.CATALOG_MODEL,
    params: {
      modelGroupSlug,
      modelSlug,
    },
    query: { c_subscription: '1' },
  })
}
const isScrollEnd = ref<boolean | undefined>(false)
const isScrollStart = ref<boolean | undefined>(true)
const productList = ref<HTMLFormElement | null>(null)

const scrollLeft = () => {
  if (productList.value) {
    productList.value.scrollLeft += 440
  }
}

const scrollRight = () => {
  if (productList.value) {
    productList.value.scrollLeft -= 440
  }
}
const isHorizontalScrollEnd = (element: HTMLFormElement | null) => {
  if (element) {
    return element.scrollLeft + element.clientWidth >= element.scrollWidth
  }
}
const isHorizontalScrollStart = (element: HTMLFormElement | null) => {
  if (element) {
    return element.scrollLeft === 0
  }
}
const scroll = () => {
  isScrollEnd.value = isHorizontalScrollEnd(productList.value)
  isScrollStart.value = isHorizontalScrollStart(productList.value)
}
</script>

<style>
@media (min-width: 1200px) {
  .textSize {
    font-size: 14px !important;
  }
}
</style>